import { getCookies, setCookies } from '../Helpers';
import {
  SET_PROMO, SET_PROMO_OPEN, SET_AMOUNT_DATA, ADD_PHOTO, REMOVE_PHOTO, REPLACE_PHOTO, SET_SHOW_PHOTOS, SET_ORDER_ID,
} from '../Constants/actionTypes';

const initialState = {
  openPromo: false,
  promo: {},
  photos: (() => {
    if (getCookies('sowarna.photos')) {
      return JSON.parse(getCookies('sowarna.photos')).filter((item) => item !== null);
    }
    return [];
  })(),
  minImagesPrice: null,
  minImages: null,
  price: null,
  totalAmount: 0,
  totalAmountBeforePromo: 0,
  showPhotos: null,
  orderId: null
};

const savePhotos = (photos) => {
  setCookies('sowarna.photos', photos.map(({name, handle, points, zoom, id, actionType, url}) =>
      {
        if(url){
          return  ({ name, handle, points, zoom, id, actionType, url })
        }
      }
  ));
};

const updateTotalAmount = (state) => {
  const {
    minImagesPrice, minImages, price, photos, promo,
  } = state;

  const totalAmount = Math.ceil((minImagesPrice + ((photos.length - minImages) * price)));

  if (photos.length === 0) {
    return {
      totalAmount: 0,
    };
  }

  if (Object.keys(promo).length > 0) {
    const totalAmountAfterPromo = Math.ceil(promo.offer_type === 1
      ? totalAmount - promo.offer
      : promo.offer === 100 ? 0: totalAmount - (promo.offer * (totalAmount / 100)));

    if (promo.condition_type === 0 && totalAmount > promo.condition) {
      return {
        totalAmountBeforePromo: totalAmount,
        totalAmount: totalAmountAfterPromo,
      };
    }

    if (promo.condition_type === 1) {
      return {
        totalAmountBeforePromo: totalAmount,
        totalAmount: totalAmountAfterPromo,
      };
    }
  }

  return {
    totalAmount,
  };
};

let photosIds = 0;

export default function amount(state = initialState, action) {
  photosIds += 1;

  switch (action.type) {
    case SET_SHOW_PHOTOS:
      return { ...state, showPhotos: true };

    case SET_AMOUNT_DATA:

      const newAmount = { ...state, ...action.val };

      return { ...newAmount, ...updateTotalAmount(newAmount), orderId: null };

    case ADD_PHOTO:

      action.val[0].id = photosIds;

      const addPhotoFinal = [...state.photos, ...action.val].sort((a, b) => a.id - b.id);

      const newAddPhotos = { ...state, photos: addPhotoFinal };

      return { ...newAddPhotos, ...updateTotalAmount(newAddPhotos), orderId: null };

    case REMOVE_PHOTO:

      const removePhoto = state.photos
        .filter((item) => item.handle !== action.val.handle);

      savePhotos(removePhoto);

      const newRemovePhotos = { ...state, photos: removePhoto };

      return { ...newRemovePhotos, ...updateTotalAmount(newRemovePhotos), orderId: null };

    case REPLACE_PHOTO:

      const otherPhoto = state.photos
        .filter((item) => item.handle !== action.val.replaceWith);

      const replacePhoto = state.photos
        .filter((item) => item.handle === action.val.replaceWith);

      if (action.val.photos[0].actionType === 'replaceByBlob') {
        replacePhoto[0].actionType = 'newPhoto';
        replacePhoto[0] = action.val.photos[0];
      }

      if (!(action.val.photos[0].actionType === 'newPhoto' && replacePhoto[0].actionType === 'editPhoto')) {
        replacePhoto[0].actionType = action.val.photos[0].actionType;
        replacePhoto[0].points = action.val.photos[0].points;
        replacePhoto[0].zoom = action.val.photos[0].zoom;
      }

      replacePhoto[0].url = action.val.photos[0].url;

      const replacePhotoFinal = [...otherPhoto, ...replacePhoto].sort((a, b) => a.id - b.id);

      savePhotos(replacePhotoFinal);

      const replace = { ...state, photos: replacePhotoFinal };
      return { ...replace, ...updateTotalAmount(replace) };

    case SET_PROMO_OPEN:

      return {
        ...state,
        openPromo: action.val,
      };

    case SET_PROMO:
      const newStatePromo = { ...state, promo: action.val };

      return { ...newStatePromo, ...updateTotalAmount(newStatePromo), orderId: null };

    case  SET_ORDER_ID:
      return { ...state, orderId: action.val };

    default:
      return state;
  }
}
