import { SET_TRANSACTION } from '../Constants/actionTypes';

const initialState = { };

export default function address(state = initialState, action) {
	switch (action.type) {
		case SET_TRANSACTION:
			return action.val;
		default:
			return state;
	}
}
