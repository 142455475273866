export { default as checkout } from './checkout';
export { COD, VCASH, saveLead } from './checkout';
export { default as content } from './content';
export { default as promo } from './promo';
export { default as pagesList } from './pagesList';
export { default as order } from './order';
export { default as changePaymentType } from './changePaymentType';



