function applyRotation(file, orientation, maxWidth) {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = () => {
      const url = reader.result;
      const image = new Image();
      image.onload = () => {
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        let { width, height } = image;
        const [outputWidth, outputHeight] =					orientation >= 5 && orientation <= 8 ? [height, width] : [width, height];
        const scale = outputWidth > maxWidth ? maxWidth / outputWidth : 1;
        width *= scale;
        height *= scale;
        // set proper canvas dimensions before transform & export
        canvas.width = outputWidth * scale;
        canvas.height = outputHeight * scale;
        // transform context before drawing image

        switch (orientation) {
          case 2:
            context.transform(-1, 0, 0, 1, width, 0);
            break;
          case 3:
            context.transform(-1, 0, 0, -1, width, height);
            break;
          case 4:
            context.transform(1, 0, 0, -1, 0, height);
            break;
          case 5:
            context.transform(0, 1, 1, 0, 0, 0);
            break;
          case 6:
            context.transform(0, 1, -1, 0, height, 0);
            break;
          case 7:
            context.transform(0, -1, -1, 0, height, width);
            break;
          case 8:
            context.transform(0, -1, 1, 0, 0, height);
            break;
          default:
            break;
        }
        // draw image
        context.drawImage(image, 0, 0, width, height);
        // export base64
        resolve(canvas.toDataURL('image/jpeg'));
      };
      image.src = url;
    };
    reader.readAsDataURL(file);
  });
}

export default applyRotation;
