import { SET_GIFT_STATE } from '../Constants/actionTypes';

const initialState = {
  isGift: null,
};

export default function address(state = initialState, action) {
  switch (action.type) {
    case SET_GIFT_STATE:
      return {
        isGift: action.val,
      };
    default:
      return state;
  }
}
