/* eslint-disable camelcase */
import React, { useState, useEffect, Suspense, lazy } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { CookiesProvider, useCookies } from 'react-cookie';
import { connect } from 'react-redux';
import { ThemeProvider } from '@material-ui/core/styles';
import { content, pagesList } from '../Api';
import { setSetting, setSettingOption, addPhoto, setFrame, setContent } from '../Actions';
import { setCookies } from '../Helpers';
import theme from '../Theme/muiTheme';
import Loadable from 'react-loadable';
import axios from 'axios';

const customHistory = createBrowserHistory();

const LoadingPage = Loadable({
  loader: () => import("../Pages/Loading"),
  loading: () => <div>...</div>,
});

const Home = Loadable({
  loader: () => import("../Pages/Home"),
  loading: () => <LoadingPage />,
});

const Create = Loadable({
  loader: () => import("../Pages/Create"),
  loading: () => <LoadingPage />,
});

const PaymentPending = Loadable({
  loader: () => import("../Pages/PaymentPending"),
  loading: () => <LoadingPage />,
});

const Preview = Loadable({
  loader: () => import("../Pages/Preview"),
  loading: () => <LoadingPage />,
});

const Article = Loadable({
  loader: () => import("../Pages/Article"),
  loading: () => <LoadingPage />,
});

const Gift = Loadable({
  loader: () => import("../Pages/Gift"),
  loading: () => <LoadingPage />,
});

const Error404 = Loadable({
  loader: () => import("../Pages/404"),
  loading: () => <LoadingPage />,
});

function App({ setting, dispatchSetSettingOption, dispatchSetSetting, dispatchAddPhotos, dispatchSetFrame, dispatchSetContent }) {
  const { lang, dir, } = setting;
  const html = document.getElementsByTagName('html')[0];
  const [loadingContent, setloadingContent] = useState(false);
  const [cookies] = useCookies(['sowarna.setting']);


  const importStyle = (lang) => {
    import(`./Style/style.${lang}.css`).then(lang => { });
  };

  const getSettingCookie = () => {
    const name = 'sowarna.setting=';
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return false;
  }

  useEffect(() => {

    setloadingContent(false)

    content({ lang: lang.toLowerCase() })
        .then((res) => {
          const { Error, Response } = res;
          if (Error.status) {
            const { main, pages, projects, countries, currencies, languages, faqs, location} = Response;


            dispatchSetContent({ main, pages, projects, faqs});

            if (getSettingCookie()) {
              const { lang, nationality } = JSON.parse(getSettingCookie());
              const { images_price, images, price, currency_name } = countries.filter((item) => item.code.toUpperCase() === JSON.parse(getSettingCookie()).nationality.toUpperCase())[0];
              dispatchSetSetting({
                minImagesPrice: images_price,
                minImages: images,
                price,
                currency: currency_name,
              });
            }

            if(!getSettingCookie()){
              try {
                const { images_price, images, price, currency_name, language } = countries[0];
                dispatchSetSetting({ minImagesPrice: images_price, minImages: images, price  });

              }catch (e) {
                const { images_price, images, price, currency_name, language } = countries[0];
                dispatchSetSetting({ nationality: 'eg', lang: 'ar', dir: 'ltr', minImagesPrice: images_price, minImages: images, price  });
              }
            }
            dispatchSetSettingOption({ nationality: countries, currency: currencies, lang: languages, })
                .then(() => setloadingContent(true));
          }
        });
    updateDir()
  }, [lang]);

  function updateDir() {
    importStyle(lang)
    html.setAttribute('dir', dir);
    html.setAttribute('lang', lang);
    customHistory.replace({ pathname: window.location.pathname.replace(window.location.pathname.split('/')[1], lang) })
  }

  if (!loadingContent) {
    return (<LoadingPage />);
  }

  return (
    <Suspense fallback={<LoadingPage />}>
      <CookiesProvider>
        <ThemeProvider theme={theme}>
          <Router history={customHistory}>
            <Switch>

              <Route     name="home" path={`/${lang}`} history={customHistory} exact> <Home /> </Route>
              <Redirect from={`/`} to={`/${lang}`} push exact/>
              <Route     name="gift" path={`/${lang}/gift`} history={customHistory} exact> <Gift /> </Route>

              <Route     name="create" path={`/${lang}/create/:frame`} history={customHistory} exact> <Create /> </Route>

              <Redirect from={`/${lang}/create`} to={`/${lang}/create/prime`} push exact/>
              <Redirect from={`/create`} to={`/${lang}/create/prime`} push exact/>
              <Redirect from={`/create/:frame`} to={`/${lang}/create/:frame`} push exact/>

              <Route     name="payment_pending" path={`/${lang}/payment_pending`} history={customHistory}> <PaymentPending /> </Route>

              <Route     name="thank_you" path={`/${lang}/thank_you/:id`} history={customHistory} exact> <Preview /> </Route>

              <Route     name="thank_you" path={`/${lang}/thank_you`} history={customHistory} exact> <Preview /> </Route>

              <Route     name="article" path={`/${lang}/article/:id/:name`} history={customHistory} exact> <Article /> </Route>

              <Route     name="404" path={`/${lang}/404`} history={customHistory} exact> <Error404 /> </Route>
              <Route     name="any" path="*"  history={customHistory}>
                <Error404 />
              </Route>

            </Switch>
          </Router>
        </ThemeProvider>
      </CookiesProvider>
    </Suspense>
  );
}

const stateToProps = (state) => state;

const dispatchToProps = (dispatch) => ({
  dispatchSetSetting: (val) => dispatch(setSetting(val)),
  dispatchSetSettingOption: (val) => dispatch(setSettingOption(val)),
  dispatchAddPhotos: (val) => dispatch(addPhoto(val)),
  dispatchSetFrame: (val) => dispatch(setFrame(val)),
  dispatchSetContent: (val) => dispatch(setContent(val)),
});

export default connect(stateToProps, dispatchToProps)(App);
