import { SET_CONTENT } from '../Constants/actionTypes';

const initialState = {
  content: { },
};

export default function content(state = initialState.content, action) {
  switch (action.type) {
    case SET_CONTENT:
      return {
        ...state,
        ...action.val,
      };
    default:
      return state;
  }
}
