import { setCookies } from '../Helpers/cookies';
import { SET_ADDRESS } from '../Constants/actionTypes';
import { setOrderId } from '../Actions';
const initialState = {
  address: { },
};

export default function address(state = initialState, action) {
  switch (action.type) {
    case SET_ADDRESS:
      setCookies('sowarna.address', action.val);
      setOrderId(null);
      return {
        address: action.val,
      };
    default:
      return state;
  }
}
