import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import Loadable from 'react-loadable';
import TagManager from 'react-gtm-module';
import App from './App';
import configureStore from './Reducers';
import * as serviceWorker from './serviceWorker';
import './I18n';


const store = configureStore( window._REDUX_STATE_ || {} );
const tagManagerArgs = {
  gtmId: 'GTM-554MZ8B',
};

TagManager.initialize(tagManagerArgs);

const AppBundle = (
      <Provider store={store}>
        <App />
      </Provider>
);

window.onload = () => {
    Loadable.preloadReady().then(() => {
        ReactDOM.render(
            AppBundle,
            document.getElementById('root'),
        );
    });
};


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
window.console.warn = () => {};
// window.console.log = () => {};
