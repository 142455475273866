import { setCookies, getCookies } from '../Helpers';
import { SET_FRAME } from '../Constants/actionTypes';

const initialState = {
  frame: {
    name: (() => {
      // if (getCookies('sowarna.frame')) {
      //   return getCookies('sowarna.frame');
      // }
      return 'PRIME';
    })(),
  },
};


export default function frame(state = initialState.frame, action) {
  switch (action.type) {
    case SET_FRAME:

      const newFrame = {
        ...state.frame,
        name: action.val,
      };

      setCookies('sowarna.frame', newFrame.name);
      return newFrame;

    default:
      return state;
  }
}
