import axios from 'axios';
import { home } from '../Constants/endPoint';
import logs from './logs';

export default function (param) {
  return new Promise((resolve, reject) => {
    axios.get(home, { params: param })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
