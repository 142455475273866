export { saveAddress } from './address';
export { setAddress } from './address';
export { setContent } from './content';
export { setFrame } from './frame';
export { addPhoto } from './photos';
export { removePhoto } from './photos';
export { replacePhoto } from './photos';
export { showPhotos } from './photos';
export { setOrderId } from './photos';
export { setOpenPromo } from './promo';
export { setPromo } from './promo';
export { setSetting } from './setting';
export { setSettingOption } from './setting';
export { setGiftState } from './gift';
