// import applyRotation from './applyRotation'


// const dataURLToBlob = function(dataURL) {
// 	let BASE64_MARKER = ';base64,';
// 	if (dataURL.indexOf(BASE64_MARKER) == -1) {
// 		let parts = dataURL.split(',');
// 		let contentType = parts[0].split(':')[1];
// 		let raw = parts[1];
//
// 		return new Blob([raw], {type: contentType});
// 	}
//
// 	let parts = dataURL.split(BASE64_MARKER);
// 	let contentType = parts[0].split(':')[1];
// 	let raw = window.atob(parts[1]);
// 	let rawLength = raw.length;
//
// 	let uInt8Array = new Uint8Array(rawLength);
//
// 	for (let i = 0; i < rawLength; ++i) {
// 		uInt8Array[i] = raw.charCodeAt(i);
// 	}
//
// 	return new Blob([uInt8Array], {type: contentType});
// }

const resizeBase64 = (file, points, scale, width, orientation, zoom, target) => new Promise((resolve) => {
  const DOMURL = window.URL || window.webkitURL || window;
  const image = new Image();
  image.onload = function (e) {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    if (width === null) {
      canvas.width = image.naturalWidth;
      canvas.height = image.naturalHeight;
    }
    // if(target === 'uplode' && image.naturalWidth > 2000){
    // 	    console.log(file.size, "1")
    // 		canvas.width = 2000;
    // 		canvas.height = 2000 * (image.naturalHeight / image.naturalWidth);
    // 	console.log(canvas.width, canvas.height, "1")
    // }else if(target === 'uplode' && image.naturalHeight > 2000){
    // 	console.log(file.size, "2")
    // 	canvas.width = 2000 * (image.naturalWidth / image.naturalHeight);
    // 	canvas.height = 2000;
    // 	console.log(canvas.width, canvas.height, "2")
    // }

    if (width) {
      canvas.width = width * 2;
      canvas.height = width * 2;
    }


    if (orientation) {
      switch (orientation) {
        case 2:
          ctx.transform(-1, 0, 0, 1, canvas.width, 0);
          break;
        case 3:
          ctx.transform(-1, 0, 0, -1, canvas.width, canvas.height);
          break;
        case 4:
          ctx.transform(1, 0, 0, -1, 0, canvas.height);
          break;
        case 5:
          ctx.transform(0, 1, 1, 0, 0, 0);
          break;
        case 6:
          ctx.translate(canvas.width, 0);
          ctx.scale(-1, 1);
          ctx.transform(-1, 0, 0, 1, canvas.width, 0);

          // ctx.transform(0, 1, -1, 0, canvas.height, 0);
          break;
        case 7:
          ctx.transform(0, -1, -1, 0, canvas.height, canvas.width);
          break;
        case 8:
          ctx.transform(0, -1, 1, 0, 0, canvas.height);
          break;
        default:
          break;
      }
    }

    if (points === null) {
      ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
    } else {
      ctx.drawImage(image,
        Number(points[0]), Number(points[1]),
        Number(points[2]) - Number(points[0]), Number(points[3]) - Number(points[1]),
        0, 0,
        width * 2, width * 2);
    }

    canvas.toBlob((blob) => {
      resolve(DOMURL.createObjectURL(blob));
      DOMURL.revokeObjectURL(file);
    }, 'image/jpeg', scale);
  };

  image.src = DOMURL.createObjectURL(file);
});
export default resizeBase64;
