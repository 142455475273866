export const LOGIN = 'LOGIN';
export const ADD_PHOTO = 'ADD_PHOTO';
export const REMOVE_PHOTO = 'REMOVE_PHOTO';
export const REPLACE_PHOTO = 'REPLACE_PHOTO';
export const SET_FRAME = 'SET_FRAME';
export const SET_PROMO = 'SET_PROMO';
export const SET_PROMO_OPEN = 'SET_PROMO_OPEN';
export const SET_CONTENT = 'SET_CONTENT';
export const SET_SETTING = 'SET_SETTING';
export const SET_SETTING_OPTION = 'SET_SETTING_OPTION';
export const SET_ADDRESS = 'SET_ADDRESS';
export const SAVEING_ADDRESS = 'SAVEING_ADDRESS';
export const SET_AMOUNT_DATA = 'SET_AMOUNT_DATA';
export const SET_SHOW_PHOTOS = 'SET_SHOW_PHOTOS';
export const SET_GIFT_STATE = 'SET_GIFT_STATE';
export const SET_TRANSACTION = 'SET_TRANSACTION';
export const SET_ORDER_ID = 'SET_ORDER_ID';
