import i18next from 'i18next';
import { setCookies, getCookies } from '../Helpers';
import { SET_SETTING, SET_SETTING_OPTION } from '../Constants/actionTypes';


const defaultSetting = {
  lang: 'ar',
  dir: 'rtl',
  nationality: 'eg',
  currency: 'EGP',
  api: null,
  minImagesPrice: null,
  minImages: null,
  price: null,
  settingsOptions: {
    nationality: null,
    currency: null,
    lang: null,
  },
};

const initialState = {
  setting: {
    ...defaultSetting,
    ...(() => {
      if (getCookies('sowarna.setting')) {
        return JSON.parse(getCookies('sowarna.setting'));
      }
      return {};
    })(),
  },
};


export default function setting(state = initialState.setting, action) {
  switch (action.type) {
    case SET_SETTING:
      let newVal;

      if (action.val.lang && state.settingsOptions.lang) {
        const dir = state.settingsOptions.lang
          .filter((item) => item.code.toLowerCase() === action.val.lang)[0].direction;

        newVal = {
          ...newVal, ...action.val, dir,
        };
      } else {
        newVal = action.val;
      }

      if ((action.val.nationality || action.val.country_id) && state.settingsOptions.nationality) {
        const currency = state.settingsOptions.nationality.filter((item) => {
          if (action.val.country_id) {
            return Number(item.id) === Number(action.val.country_id);
          }

          return item.code.toUpperCase() === action.val.nationality.toUpperCase();
        })[0].currency_name;

        if (action.val.country_id) {
          const nationality = state.settingsOptions.nationality
            .filter((item) => Number(item.id) === Number(action.val.country_id))[0].code;
          newVal = {
            ...action.val, nationality,
          };
        }

        newVal = {
          ...newVal, ...action.val, currency,
        };
      }

      if (action.val.lang) {
        setTimeout(() => {
          i18next.changeLanguage(action.val.lang.toLowerCase());
        }, 100);
      }

      const newState = { ...state, ...newVal };

      const {
        lang, dir, nationality, currency, api,
      } = newState;

      setCookies('sowarna.setting', {
        lang, dir, nationality, currency, api,
      });

      return newState;

    case SET_SETTING_OPTION:

      return { ...state, settingsOptions: action.val };

    default:

      return state;
  }
}
