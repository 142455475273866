/* no-bitwise */
function gunkey(param) {
  let id;
  function hash(str) {
    let $hash = 0;
    let i;
    let chr;
    const length = (typeof param !== 'string' && str.length > 15) ? 15 : str.length;
    for (i = 0; i < length; i += 1) {
      chr = str.charCodeAt(i);
      /* eslint-disable no-bitwise */
      $hash = (($hash << 5) - $hash) + chr;
      $hash |= 0;
      /* eslint-disable no-bitwise */
    }
    return Math.abs($hash);
  }

  /* eslint-disable no-bitwise */
  const S4 = (h) => ((h * 0x10000) | 0).toString(16).substring(1);
  /* eslint-disable no-bitwise */

  if (typeof param === 'string') {
    id = hash(param);
  }

  if (typeof param === 'undefined') {
    id = (1 + Math.random());
  }

  if (typeof param === 'object') {
    id = hash(JSON.stringify(param));
  }

  if (typeof param === 'number' || typeof param === 'bigint') {
    id = param;
  }

  if (typeof param === 'boolean' || typeof param === 'function' || typeof param === 'symbol') {
    throw new Error('Whoops!');
    return param;
  }

  return (S4(id) + S4(Number((id).toString().split('').reverse().join(''))));
}

export default gunkey;
