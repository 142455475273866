import * as types from '../Constants/actionTypes';

export const setSetting = (val) => (dispatch) => {
  if (val.minImagesPrice) {
    const { minImagesPrice, minImages, price } = val;
    dispatch((
      {
        type: types.SET_AMOUNT_DATA,
        val: { minImagesPrice, minImages, price },
      }
    ));
  }
  dispatch(({ type: types.SET_SETTING, val }));
};

export const setSettingOption = (val) => (dispatch) => new Promise((resolve) => {
  dispatch(({ type: types.SET_SETTING_OPTION, val }));
  resolve();
});
