import axios from 'axios';
import { changePaymentType } from '../Constants/endPoint';

export default function (param) {
  return new Promise((resolve, reject) => {
    axios.post(changePaymentType, param)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
