module.exports = {
  primary: '#050259',
  primaryDark: '#02003a',
  secondary: '#FD0068',
  secondaryDark: '#cd0156',
  third: '#9fd9ff',
  thirdDark: '#5ec1ff',
  fourth: '#2d48d0',
  fourthDark: '#5A47CE',
  fourthDarkDark: '#4331be',

  danger: '#d32f2f',
  alert: '#ffa000',
  success: '#388e3c',
  white: '#ffffff',
  offWhite: '#f0f0f0',
  black: '#212121',
  grayscale: '#797979',
  blue: '#2d48d0',
  blue2: '#473db2',
  red: '#973b6d',
  orange: '#f27f1b',
};
