import Cookies from 'js-cookie';

function setCookies(name, data) {
  const today = new Date();
  const expire = new Date();
  expire.setTime(today.getTime() + 3600000 * 24 * 14);
  Cookies.set(name, data, { path: '/', expires: expire });
}

function getCookies(name) {
  return Cookies.get(name);
}

export { setCookies, getCookies };
