import axios from 'axios';

const axiosInstance = axios.create({
    baseURL: 'http://log.360marketingtec.com/',
    headers: {
        'Access-Control-Allow-Origin': '*'
    }
});

export default {
    createId: function () {
        const ID = '_' + Math.random().toString(36).substr(2, 9);
        document.cookie = `log.id=${ID}`;
    },
    getId: function () {
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(';');
        for(var i = 0; i <ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf("log.id=") == 0) {
                return c.substring("log.id=".length, c.length);
            }
        }
        return "";
    },
    log: function (data) {
        return new Promise((resolve, reject) => {
            axiosInstance.post('', {file_name: this.getId(), data: JSON.stringify(data) })
                .then((response) => {
                    console.log(response)
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
}
