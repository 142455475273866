import axios from 'axios';
import { order } from '../Constants/endPoint';

export default function (param) {
  return new Promise((resolve, reject) => {
    axios.post(`${order}/${param.id}`, param, {
      headers: {
        'Access-Control-Allow-Origin': '*',
      },
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
