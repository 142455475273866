import axios from 'axios';
import { checkout, checkoutCOD, saveLeadUrl } from '../Constants/endPoint';

function COD(param) {
  return new Promise((resolve, reject) => {
    axios({
      method: 'post',
      url: checkoutCOD,
      data: param,
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((response) => {
      resolve(response.data);
    })
      .catch((error) => {
        reject(error);
      });
  });
}

function VCASH(param) {
  return new Promise((resolve, reject) => {
    axios({
      method: 'post',
      url: checkoutCOD,
      data: param,
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((response) => {
      resolve(response.data);
    })
      .catch((error) => {
        reject(error);
      });
  });
}

function saveLead(param) {
  return new Promise((resolve, reject) => {
    axios({
      method: 'post',
      url: saveLeadUrl,
      data: param,
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((response) => {
      resolve(response.data);
    })
      .catch((error) => {
        reject(error);
      });
  });
}

export default function (param) {
  return new Promise((resolve, reject) => {
    axios({
      method: 'post',
      url: checkout,
      data: param,
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((response) => {
      resolve(response.data);
    })
      .catch((error) => {
        reject(error);
      });
  });
}



export { COD, VCASH, saveLead };
