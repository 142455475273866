import { createMuiTheme } from '@material-ui/core/styles';
import colors from './colors';

export default createMuiTheme({
  palette: {
    primary: {
      light: colors.fourthDark,
      main: colors.fourthDark,
      dark: colors.fourthDarkDark,
      contrastText: '#fff',
    },
    secondary: {
      light: colors.secondary,
      main: colors.secondary,
      dark: colors.secondaryDark,
      contrastText: '#fff',
    },
  },
});
