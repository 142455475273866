export const base = process.env.NODE_ENV === 'development'
  ? '/'
  // ? 'http://localhost:8000/'
  : '/';
export const api = 'api/';
export const home = `${base}${api}home`;
export const checkPromo = `${base}${api}check_promo`;
export const checkout = `${base}${api}checkout`;
export const checkoutCOD = `${base}${api}checkout_delivery`;
export const trans = `${base}${api}translations`;
export const pagesList = `${base}${api}pages`;
export const order = `${base}${api}order`;
export const changePaymentType = `${base}${api}order/update`;
export const saveLeadUrl = `${base}${api}saveLead`;
