import { createStore, combineReducers, compose, applyMiddleware, } from 'redux';
// import { composeWithDevTools } from 'redux-devtools-extension';
import ReduxThunk from 'redux-thunk';

import address from './address';
import amount from './amount';
import content from './content';
import frame from './frame';
import gift from './gift';
import setting from './setting';
import transaction from './transaction.js';

const createStoreWithMiddleware = compose(applyMiddleware(ReduxThunk))(createStore);

const rootReducer = combineReducers({
  address,
  amount,
  content,
  frame,
  gift,
  transaction,
  setting,
});

export default function configureStore(initialState = {}) {
  return createStoreWithMiddleware(rootReducer, initialState);
};
