import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-xhr-backend';
import { trans } from '../Constants/endPoint';
import words from './locales/words';
import { getCookies } from '../Helpers';

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    react: {
      useSuspense: true,
    },
    backend: {
      method: 'POST',
      loadPath: `${trans}?lang={{lng}}`,
      allowMultiLoading: true,
      crossDomain: true,
      withCredentials: false,
      queryStringParams: {
        words,
      },
      parse(data) {
        return JSON.parse(data).Response;
      },
    },
    fallbackLng: ['ar', 'en'],
    lng: (() => {
      if (getCookies('sowarna.setting')) {
        return JSON.parse(getCookies('sowarna.setting')).lang;
      }
      return 'ar';
    })(),
    interpolation: {
      escapeValue: false,
    },
  });


export default i18n;
